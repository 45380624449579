import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Home from './screens/Home';
import About from './screens/About';
import Blog from './screens/Blog';
import Now from './screens/Now';
import MonthNotes from './screens/MonthNotes';

import DeployReactFirebase from './screens/blog-articles/DeployReactToFirebase';
import GithubActionsFirebase from './screens/blog-articles/GithubActionsWithFirebase';

import ScrollToTop from './components/ScrollToTop';
import BooksRead2022 from './screens/BooksRead2022';
import BooksRead2024 from './screens/BooksRead2024';
import MonthNotes2022 from './screens/MonthNotes2022';
import January2022 from './screens/month-notes-entries/January2022';
import February2022 from './screens/month-notes-entries/February2022';
import March2022 from './screens/month-notes-entries/March2022';
import April2022 from './screens/month-notes-entries/April2022';
import May2022 from './screens/month-notes-entries/May2022';
import July2022 from './screens/month-notes-entries/July2022';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route exact path="/blog" component={Blog} />
        <Route path="/blog/deploy-react-app-to-firebase" component={DeployReactFirebase} />
        <Route path="/blog/github-actions-with-firebase" component={GithubActionsFirebase} />
        <Route path="/now" component={Now} />
        <Route exact path="/books-read/2022" component={BooksRead2022} />
        <Route exact path="/books-read/2024" component={BooksRead2024} />
        <Route exact path="/month-notes" component={MonthNotes} />
        <Route exact path="/month-notes/2022" component={MonthNotes2022} />
        <Route path="/month-notes/2022/january" component={January2022} />
        <Route path="/month-notes/2022/february" component={February2022} />
        <Route path="/month-notes/2022/march" component={March2022} />
        <Route path="/month-notes/2022/april" component={April2022} />
        <Route path="/month-notes/2022/may" component={May2022} />
        <Route path="/month-notes/2022/july" component={July2022} />
      </Switch>
    </Router>
  );
}

export default App;
