import React from 'react';

import ArticleList from '../components/ArticleList';
import Footer from '../components/Footer';
import TopNavBar from '../components/TopNavBar';

function Home() {

    var words = ["Full stack developer", "Tool maker", " Creative entrepreneur"];  
    let currentWord = -1; 

    window.setInterval(function(){ 
    const element = document.getElementById("slogan");
    
    if (element) {
        currentWord++; 
        if(currentWord >= words.length) {
            currentWord = 0;
        }  
        element.innerHTML = " " + words[currentWord]; 
    }
    
    }, 4000); 
 
    return (
        <div className="flex-wrapper">
            <div className="container">
                <TopNavBar />
                <h1 className="title text-center"> Hi, I'm David! 👋 </h1>
                <p className="sub-title text-center" id="slogan">Creative entrepreneur</p>
                <h3 className="projects-presentation text-center">Currently building this startups</h3>
                <div className="row startups-presentation">
                    <div className="col-12 col-md-6">
                        <h3 className="text-center">Joinstack</h3>
                        <p className="text-center startups-description">A software development company focused on creating mobile and web apps for your daily needs.</p>
                        <p className="text-center startups-link"><a href="https://joinstack.tech">Check out Joinstack →</a></p>
                    </div>
                    <div className="col-12 col-md-6">
                        <h3 className="text-center">UiRoute</h3>
                        <p className="text-center startups-description">Marketplace for UI Templates and Kits designed and built for awesome developers.</p>
                        <p className="text-center startups-link"><a href="https://uiroute.com">Check out UiRoute →</a></p>
                    </div>
                </div>
                <h3 className="article-presentation text-center">Writing about life and developing awesome software</h3>
                <ArticleList />
                <h3 className="article-presentation text-center">And always building something new</h3>
                <img src="http://ghchart.rshah.org/davidbanu" alt="David's Github Chart" className="img-responsive center-block"></img>
            </div>
            <Footer />
        </div>
    )
}

export default Home;
