import React from 'react';

import TopNavBar from '../components/TopNavBar';
import Footer from '../components/Footer';

function MonthNotes2022() {
    return (
        <div className="flex-wrapper">
            <div className="container">
                <TopNavBar />
                <h1 className="about-title text-center">Month Notes - 2022</h1>
                <p className="text-now-page text-center">This is the list with my 2022 month notes.</p>
                <p className="text-now-page text-center"><a className="now-link" href="">October 2022</a></p>
                <p className="text-now-page text-center"><a className="now-link" href="">September 2022</a></p>
                <p className="text-now-page text-center"><a className="now-link" href="">August 2022</a></p>
                <p className="text-now-page text-center"><a className="now-link" href="/month-notes/2022/july">July 2022</a></p>
                <p className="text-now-page text-center"><a className="now-link" href="">June 2022</a></p>
                <p className="text-now-page text-center"><a className="now-link" href="/month-notes/2022/may">May 2022</a></p>
                <p className="text-now-page text-center"><a className="now-link" href="/month-notes/2022/april">April 2022</a></p>
                <p className="text-now-page text-center"><a className="now-link" href="/month-notes/2022/march">March 2022</a></p>
                <p className="text-now-page text-center"><a className="now-link" href="/month-notes/2022/february">February 2022</a></p>
                <p className="text-now-page text-center"><a className="now-link" href="/month-notes/2022/january">January 2022</a></p>
            </div>
            <Footer />
        </div>
    )
}

export default MonthNotes2022;