import React from 'react';


import TopNavBar from '../../components/TopNavBar';
import Footer from '../../components/Footer';

import mainImage from '../../images/may-picture.jpeg';

function May2022() {

    return (

        <div className="flex-wrapper">

            <div className="container">

                <TopNavBar />

                <h1 className="about-title text-center">May 2022</h1>

                <p className="title-grayed text-center"> Working hard, fighting dragons...</p>

                <img src={mainImage} className="img-responsive center-block img-posts"></img>

                <p className="text-now-page"> May was a hard month, worked a lot, made good improvements with all the products that I'm developing right now and had time for resting. I fought with some dragons in one of the projects that I'm currently working, but in the end I've managed to find a way to tackle everything.
                </p>
                <p className="text-now-page">In May, the Ethereum price dropped and I lost a lot of money. This put a lot of stress on my shoulders, and a lot of disappointment on my view about crypto investing. 
                I'm okay right now, and I know that investing in crypto means you accept the risks and you have the will to wait for better times.  
                </p>

                <h3 className="month-subtitle"> Things to work on in the future</h3>

                <p className="text-now-page"> First thing that I want to change for the future is to have an ideal weight. I still need to lose around 15kg.
                Finally, I want to work on my procrastination. Even though I do a lot
                of things every day, I feel that I can have more results if I focus more and get shit done.</p>

                <h3 className="month-subtitle"> What I want to do next month</h3>

                <p className="text-now-page"> A 4 day holiday with my family, focusing mainly on the Joinstack projects that we currently have in progress. 
                I also want to continue the weight loss process (my goal is 4 kg's this month), create a website for <a className="now-link" href="https://mvpmonth.com">MVP Month</a>,
                launch a new product for future developers, <a className="now-link" href="https://coderpaths.com">Coder Paths</a>, 
                launching the first version of an app for time control (DoFocus) 
                on Android and iOS, develop an Alpha version of a <a className="now-link" href="https://scrumlivepoker.com">Scrum Poker app</a>, continue investing in stock and crypto, creating my first collection of NFT's, write 2 chapters for the book that I'm trying to launch, 
                finish some tasks for 4 crypto projects, launching a npm package, and finally launching two free themes 
                (Lansera and Agency) for <a className="now-link" href="https://uiroute.com">uiroute.com</a>.</p>

                <div className="row cards-stats-books">
                    <h3 className="text-center">Month stats</h3>
                     <div className="col-md-4 col-xs-12 padding-top-5">
                        <p className="text-center numbers-subtitle">1</p>
                        <p className="text-center text-numbers">Book</p>
                    </div>
                    <div className="col-md-4 col-xs-12 padding-top-5">
                        <p className="text-center numbers-subtitle">247</p>
                        <p className="text-center text-numbers">Worked hours</p>
                    </div>
                    <div className="col-md-4 col-xs-12 padding-top-5">
                        <p className="text-center numbers-subtitle">26</p>
                        <p className="text-center text-numbers">Meetings</p>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-xs-12 padding-top-5">
                            <p className="text-center numbers-subtitle">1 kg</p>
                            <p className="text-center text-numbers">Weight loss</p>
                        </div>
                        <div className="col-md-4 col-xs-12 padding-top-5">
                            <p className="text-center numbers-subtitle">121</p>
                            <p className="text-center text-numbers">Commits</p>
                        </div>
                        <div className="col-md-4 col-xs-12 padding-top-5">
                            <p className="text-center numbers-subtitle">-26.7 %</p>
                            <p className="text-center text-numbers">Return of Investment</p>
                        </div>
                    </div>
                </div>

            </div>

            <Footer />

        </div>

    )

}



export default May2022;

