import React from 'react';

import TopNavBar from '../components/TopNavBar';
import Footer from '../components/Footer';

function BooksRead2022() {
    return (
        <div className="flex-wrapper">
            <div className="container">
                <TopNavBar />
                <h1 className="about-title text-center">2022 Books</h1>
                <h4 className="title-grayed text-center">Books that I've read in 2022</h4>
                <p className="text-now-page text-center">- Shoe Dog by Phil Knight</p>
                <p className="text-now-page text-center">- 12 Rules for life by Jordan Peterson</p>
                <p className="text-now-page text-center">- The infinite game by Simon Sinek</p>
                <p className="text-now-page text-center">- Models by Mark Manson</p>
                <p className="text-now-page text-center">- No more Mr. Nice Guy by Robert A. Glover</p>
                <p className="text-now-page text-center">- Evolving architectures of fintech</p>
                <p className="text-now-page text-center">- Man's search for meaning by Viktor Frankl</p>
                <p className="text-now-page text-center">- Feeling is the secret by Neville Goddard </p>
                <p className="text-now-page text-center">- The brain and emotional intelligence by Daniel Goleman</p>
                <p className="text-now-page text-center">- Freakonomics by Levitt & Dubner</p>
                <p className="text-now-page text-center">- We need to talk about Putin by Galeotti</p>
                <p className="text-now-page text-center">- The almanack of Naval Ravikant by Jorgenson</p>
                <p className="text-now-page text-center">- The Twins of Auschwitz by Eva Mozes Kor</p>
                <p className="text-now-page text-center">- The unfair advantage by Ash Ali</p>
                <p className="text-now-page text-center">- Self Discipline by Curtis Leone</p>
                <div className="row cards-stats-books">
                    <div className="col-md-4 col-xs-12">
                        <p className="text-center numbers-subtitle">15</p>
                        <p className="text-center text-numbers">Books</p>
                    </div>
                    <div className="col-md-4 col-xs-12">
                        <p className="text-center numbers-subtitle">3673</p>
                        <p className="text-center text-numbers">Pages</p>
                    </div>
                    <div className="col-md-4 col-xs-12">
                        <p className="text-center numbers-subtitle">123</p>
                        <p className="text-center text-numbers">Hours</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default BooksRead2022;
