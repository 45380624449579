import React from 'react';


import TopNavBar from '../../components/TopNavBar';
import Footer from '../../components/Footer';



function GithubActionsFirebase() {

    return (

        <div className="flex-wrapper">

            <div className="container">

                <TopNavBar />

                <h1 className="about-title text-center">Github Actions: Continuous Delivery with Firebase</h1>

                <p className="title-grayed text-center"> Using workflows in GitHub Actions to deploy pull requests automatically to a staging environment</p>

            </div>

            <Footer />

        </div>

    )

};



export default GithubActionsFirebase;
