import React from 'react';

import TopNavBar from '../components/TopNavBar';
import Footer from '../components/Footer';

function Now() {
    return (
        <div className="flex-wrapper">
            <div className="container">
                <TopNavBar />
                <h1 className="about-title text-center">Now</h1>
                <p className="title-grayed text-center">This is a now page. It's about what I'm doing at this point in my life.</p>
                <h3 className="bold-font">🎯 Main focus</h3>
                <p className="text-now-page">Constantly focusing on developing <a className="now-link" href="https://joinstack.tech">Joinstack</a> and <a className="now-link" href="https://uiroute.com">UiRoute</a>. Writing my first book, Worth it, that will be available this year. Having fun every day while following my dreams.</p>
                <h3 className="bold-font">👨‍🎓 Learning</h3>
                <p className="text-now-page">I'm currently learning for the Google Project Management: Professional Certificate</p>
                <h3 className="bold-font">📖 Reading</h3>
                <p className="text-now-page">- We are all weird by Seth Godin</p>
                <p className="text-now-page">- Observability Engineering</p>
                <p className="text-now-page"><a className="now-link" href="/books-read/2024">Here</a> you can find a complete list of all the books I've read in 2024.</p>
                <p className="text-now-page">And <a className="now-link" href="/books-read/2022">here</a> you can find the list of all the books I've read in 2022.</p>
                <h3 className="bold-font">📪 Contact me</h3>
                <p className="text-now-page"> You can contact me by email at <a className="now-link" href = "mailto: david@joinstack.tech">david@joinstack.tech</a> to say hi! I always appreciate meeting new people.</p>
            </div>
            <Footer />
        </div>
    )
}

export default Now;
