import React from 'react';

import TopNavBar from '../components/TopNavBar';
import Footer from '../components/Footer';

function BooksRead2024() {
    return (
        <div className="flex-wrapper">
            <div className="container">
                <TopNavBar />
                <h1 className="about-title text-center">2024 Books</h1>
                <h4 className="title-grayed text-center">Books that I've read in 2024</h4>
                <p className="text-now-page text-center">- Company of one by Paul Jarvis</p>
                <div className="row cards-stats-books">
                    <div className="col-md-4 col-xs-12">
                        <p className="text-center numbers-subtitle">0</p>
                        <p className="text-center text-numbers">Books</p>
                    </div>
                    <div className="col-md-4 col-xs-12">
                        <p className="text-center numbers-subtitle">100</p>
                        <p className="text-center text-numbers">Pages</p>
                    </div>
                    <div className="col-md-4 col-xs-12">
                        <p className="text-center numbers-subtitle">2</p>
                        <p className="text-center text-numbers">Hours</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default BooksRead2024;
