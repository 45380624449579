import React from 'react';


import TopNavBar from '../../components/TopNavBar';
import Footer from '../../components/Footer';

import mainImage from '../../images/july-image.jpg';

function July2022() {

    return (

        <div className="flex-wrapper">

            <div className="container">

                <TopNavBar />

                <h1 className="about-title text-center">July 2022</h1>

                <p className="title-grayed text-center"> Breaks are good sometimes...</p>

                <img src={mainImage} className="img-responsive center-block img-posts"></img>

                <p className="text-now-page"> July was a good month, worked a lot, had some time to explore the city of Krakow, visited Auschwitz and the Oskar Schindler museum and made some good progress with my side projects.
                </p>
                <p className="text-now-page">In July, the price of Ethereum grew a lot and I started again to have hope in it. I still think the Ethereum will take a turn for the better and will reach the 4,000 euro mark till the end of the year.  
                </p>

                <h3 className="month-subtitle"> Things to work on in the future</h3>

                <p className="text-now-page"> Be more proactive in all that I do, balance the work and my life better. Have more time for friends and family. And eat more healthier.</p>

                <h3 className="month-subtitle"> What I want to do next month</h3>

                <p className="text-now-page"> A 8 day holiday in Vienna, Bratislava and Brno, where I want to disconnect from work, explore the cities and relax.
                I also want to create a website for <a className="now-link" href="https://mvpmonth.com">MVP Month</a>,
                launch a new product for future developers, <a className="now-link" href="https://coderpaths.com">Coder Paths</a>, 
                develop an Alpha version for a <a className="now-link" href="https://scrumlivepoker.com">Scrum Poker app</a>, continue investing in stock and crypto, create my first collection of NFT's, write 2 chapters for the book that I'm trying to launch this year, 
                release a npm package, and finally launching two free themes 
                (Lansera and Agency) for <a className="now-link" href="https://uiroute.com">uiroute.com</a>.</p>

                <div className="row cards-stats-books">
                    <h3 className="text-center">Month stats</h3>
                     <div className="col-md-4 col-xs-12 padding-top-5">
                        <p className="text-center numbers-subtitle">0</p>
                        <p className="text-center text-numbers">Books</p>
                    </div>
                    <div className="col-md-4 col-xs-12 padding-top-5">
                        <p className="text-center numbers-subtitle">194</p>
                        <p className="text-center text-numbers">Worked hours</p>
                    </div>
                    <div className="col-md-4 col-xs-12 padding-top-5">
                        <p className="text-center numbers-subtitle">18</p>
                        <p className="text-center text-numbers">Meetings</p>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-xs-12 padding-top-5">
                            <p className="text-center numbers-subtitle">0 kg</p>
                            <p className="text-center text-numbers">Weight loss</p>
                        </div>
                        <div className="col-md-4 col-xs-12 padding-top-5">
                            <p className="text-center numbers-subtitle">38</p>
                            <p className="text-center text-numbers">Commits</p>
                        </div>
                        <div className="col-md-4 col-xs-12 padding-top-5">
                            <p className="text-center numbers-subtitle">62.33 %</p>
                            <p className="text-center text-numbers">Return of Investment</p>
                        </div>
                    </div>
                </div>

            </div>

            <Footer />

        </div>

    )

}



export default July2022;

