import React from 'react';


import TopNavBar from '../../components/TopNavBar';
import Footer from '../../components/Footer';



function DeployReactFirebase() {

    return (

        <div className="flex-wrapper">

            <div className="container">

                <TopNavBar />

                <h1 className="about-title text-center">How to deploy a React application to Firebase</h1>

                <p className="title-grayed text-center"> Detailed tutorial on how to deploy a React app in minutes.</p>

            </div>

            <Footer />

        </div>

    )

};



export default DeployReactFirebase;
